import React from 'react';
import { observer } from 'mobx-react';
import { autorun, computed, observable, toJS } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI, ExerciseUI } from '@seedlang/state';
import WorksheetGridItem from 'components/worksheet/worksheet_grid_item';
import autobind from 'autobind-decorator';
import WorksheetFilter from 'components/worksheet/worksheet_filter';
import { isBlank } from '@seedlang/utils';
import Paginator from 'components/paginator';
import { keys, remove, some } from "lodash";
import { isPresent } from "@seedlang/utils/src";
import Text from 'components/text';
import Spinner from "components/spinner";
import { deserialize } from 'serializr';
import { GridItemWorksheet } from '@seedlang/models';
import { Constants } from '@seedlang/constants';
import { Link } from "react-router";


const Wrapper = styled.div`
  padding-top: ${props => props.paddingTop};
`;

const Section = styled.div`
  border-radius: 10px;
  margin-bottom: 15px;
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 10px 10px 0;
  border-radius: 10px;
  cursor: pointer;
  .fa {
    font-size: 24px;
    margin: 0 10px 0 5px;
    width: 15px;
    text-align: center;
  }
  @media only screen and (max-width : 649px) {
    margin-left: 10px;
  }
`;

const SectionDetails = styled.div`
  font-weight: normal;
  color: gray;
  font-size: 14px;
  margin-right: 5px;
`;

const WorksheetWrapper = styled.div`
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: ${props => props.marginTop};
  min-height: ${props => props.minHeight};
`;

const ContentWrapper = styled.div`
  background: #FFF;
  padding: 20px;
  overflow: auto;
  max-height: 300px;
  border-radius: ${props => props.borderRadius};
  margin-bottom: 15px;
`;

const AdminLink = styled.span`
  font-size: 11px;
  text-decoration: underline;
  text-align: center;
  margin-right: 20px;
  vertical-align: bottom;
`;


@observer
class GridLayout extends React.Component {
  @observable gridIsLoaded = false
  @observable scrollEventListenerAdded = false
  @observable searchQuery = null;
  @observable sort = ExerciseUI.getCookieValue('worksheet-sort') || 'desc';
  @observable closedSections = [];
  @observable gridSectionIsLoading = {};
  @observable loadedTagCategories = AppUI.tagCategoryStore.hasIndexData;

  constructor(props) {
    super(props);
    this.getPage();
    if (!AppUI.layout.isMobile) {
      window.addEventListener('scroll', this.onScroll, true);
    }
    if (this.loadedTagCategories) {
      this.getGrid();
    }
    autorun(() => {
      if (!this.loadedTagCategories && AppUI.userIsLoaded) {
        this.loadedTagCategories = true;
        this.loadTags();
      }
    });
  }

  componentDidMount () {
    if (isPresent(ExerciseUI.userGroup) && isPresent(ExerciseUI.userGroup.closedGridSections)) {
      this.closedSections = ExerciseUI.userGroup.closedGridSections.split(',') ;
    }
  }

  componentDidUpdate(prevProps) {
    if (ExerciseUI.userIsLoaded) {
      if ((prevProps.params !== this.props.params || this.props.route.name !== prevProps.route.name) && AppUI.pageStore.requestCounter === 0) {
        this.getPage();
      }
    }
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  @autobind onScroll() {
    let page = ExerciseUI.worksheetStore.page;
    const app = document.getElementsByClassName('app')[0];
    if (!this.useGridLayout && ExerciseUI.worksheetStore.requestCounter === 0 && !ExerciseUI.worksheetStore.indexDataFullyLoaded && isPresent(app) && app.scrollTop >= document.body.scrollHeight*page - 250) {
      ExerciseUI.worksheetStore.setPage(page+1);
      ExerciseUI.worksheetStore.getIndex({limit: this.worksheetLimit, sort: this.sortParameter, filters: this.worksheetFilters, queryStrings: {show_all: !this.props.showFilters}}, this.afterOnScroll);
    }
  }

  @autobind afterOnScroll(resp) {
    ExerciseUI.worksheetStore.setIndexData(resp, {addToIndexData: true});
  }

  @computed get useGridLayout() {
    return this.props.useGridSection && (ExerciseUI.site.useGridLayout || this.props.route.name === 'worksheets.grid_with_sections') && (!ExerciseUI.site.showWorksheetFilter || !this.hasSelectedFilterTags && isPresent(AppUI.user.currentUserGroup) && AppUI.tagCategoryStore.hasIndexData && isBlank(this.searchQuery)) ;
  }

  @computed get numberColumns() {
    if (this.containerWidth > 1000) {
      return 3
    } else if (this.containerWidth > 700) {
      return 2
    }
    return 1;
  }

  @computed get containerWidth() {
    return ExerciseUI.layout.viewportWidth > 1180 ? 1160 : ExerciseUI.layout.viewportWidth - 10;
  }

  @computed get gridItemWidth() {
    let padding = 0;
    if (this.numberColumns === 3) {
      if (ExerciseUI.layout.viewportWidth > 1190) {
        padding = 10;
      } else {
        padding = 16;
      }
    } else if (this.numberColumns === 2) {
      padding = 16;
    }
    return Math.floor(this.containerWidth / this.numberColumns) - padding;
  }

  @computed get gridItemFilters() {
    let filters = {};
    return filters;
  }

  @computed get worksheetFilters() {
    let filters = {};
    if (this.props.defaultWorksheetFilter) {
      filters = this.props.defaultWorksheetFilter;
    }
    if (this.useGridLayout) {
      filters['grid_items_count'] = 0;
    }
    if (!ExerciseUI.user || !ExerciseUI.user.anyAdmin) {
      filters['published'] = true;
    }
    if (this.searchQuery) {
      filters['name'] = `~${this.searchQuery}~`;
    } else if (isBlank(this.searchQuery) && isPresent(filters['name'])) {
      delete filters['name']
    } else {
      if (this.props.params?.filter === 'video_clips') {
        filters['podcast_episode_id'] = false;
      } else if (this.props.params?.filter === 'podcasts') {
        filters['podcast_episode_id'] = true;
      }
    }
    return filters;
  }

  @autobind onSearchQueryChange(val) {
    this.searchQuery = val;
    clearInterval(this.interval);
    if (isBlank(val)) {
      this.getGrid();
    }
    if ( isBlank(val) || val.length > 1) {
      this.interval = setTimeout(this.getGrid, 500)
    }
  }

  @autobind toggleSort() {
    this.sort = this.sort === 'asc' ? 'desc' : 'asc';
    ExerciseUI.setCookieValue('worksheet-sort', this.sort)
    this.getGrid({forceLoad: true});
  }

  @computed get sortParameter() {
    if (ExerciseUI.user.isMember) {
      return this.sort === 'asc' ? '-pinned,content_published_at' : '-pinned,-content_published_at';
    }
    return this.sort === 'asc' ? '-pinned,content_published_at' : '-access_without_membership,-pinned,-content_published_at';
  }

  @autobind getGrid(params={}) {
    this.gridIsLoaded = false;
    if (this.useGridLayout && (!ExerciseUI.gridItemStore.hasIndexData || params.forceLoad)) {
      AppUI.set('worksheetGridScrollPosition', 0);
      ExerciseUI.gridItemStore.clearFilter();
      ExerciseUI.gridItemStore.getIndex({queryStrings: {sort: 'position', filters: this.gridItemFilters, worksheet_filters: this.worksheetFilters, show_all: !this.props.showFilters}}, this.getGridItems);
    } else {
      if (this.recentWorksheetIds && this.useGridLayout) {
        params.excludedWorksheetIds = this.recentWorksheetIds;
      }
      this.getWorksheets(params);
    }
  }

  @computed get worksheetLimit() {
    if (this.useGridLayout) {
      return 6;
    } else if (AppUI.layout.isMobile) {
      return 8;
    } else {
      return 15;
    }
  }

  @autobind getWorksheets(params) {
    const filtersHaveChanged = some(keys(this.worksheetFilters)?.map((key => this.worksheetFilters[key] !== ExerciseUI.worksheetStore.getFilterValue(key)))) || some(keys(toJS(ExerciseUI.worksheetStore.filters))?.map((key => this.worksheetFilters[key] !== ExerciseUI.worksheetStore.getFilterValue(key))))
    const sortHasChanged = ExerciseUI.worksheetStore.sort !== this.sort;
    if (!ExerciseUI.worksheetStore.hasIndexData || filtersHaveChanged || sortHasChanged || (isPresent(params) && params['forceLoad'])) {
      AppUI.set('worksheetGridScrollPosition', 0);
      ExerciseUI.worksheetStore.clearIndexData();
      ExerciseUI.worksheetStore.clearFilter();
      ExerciseUI.worksheetStore.getIndex({
        limit: this.worksheetLimit,
        sort: this.sortParameter,
        filters: this.worksheetFilters,
        queryStrings: {show_all: !this.props.showFilters, excluded_worksheet_ids: params?.excludedWorksheetIds, videos: this.props.route.name === 'videos.index', podcast: this.props.route.name === 'podcast.index', home: this.props.route.name === AppUI.site.signedInHomePageRoute}
      }, this.afterGetWorksheets);
    } else {
      this.gridIsLoaded = true;
    }
  }

  @autobind afterGetWorksheets(resp) {
    ExerciseUI.worksheetStore.setIndexData(resp);
    this.gridIsLoaded = true;
  }

  @autobind getGridItems(resp) {
    ExerciseUI.gridItemStore.setIndexData(resp);
    this.gridIsLoaded = true;
    this.visibleSections = resp.map(item => item.id);
    if (ExerciseUI.gridItemStore.indexData.find(item => item.mostRecent && item.isVisibleToUser)) {
      ExerciseUI.recentWorksheetStore.getIndex({queryStrings: {show_all: 'true'}}, this.afterGetRecentWorksheets);
    } else {
      this.getWorksheets();
    }
  }

  @autobind afterGetRecentWorksheets(resp) {
    ExerciseUI.recentWorksheetStore.afterGetIndex(resp);
    this.getWorksheets({excludedWorksheetIds: this.recentWorksheetIds});
  }

  @computed get recentWorksheetIds() {
    return ExerciseUI.worksheetNamespace === 'worksheets' && ExerciseUI.recentWorksheetStore.hasIndexData && ExerciseUI.recentWorksheetStore.indexData.map(item => item.id).join(',');
  }

  @autobind getPage() {
    AppUI.pageStore.clearShowData();
    let slug = 'home';
    if (ExerciseUI.worksheetNamespace !== 'worksheets') {
      slug = ExerciseUI.worksheetNamespace;
    }
    if (this.props.route.name === 'worksheets.by_filter') {
      slug = this.props.params.filter;
    }
    AppUI.pageStore.getShow({ids: {pageId: slug}});
  }

  @autobind onToggleSection(sectionId) {
    if (this.sectionIsOpen(sectionId)) {
      this.closedSections.push(sectionId);
    } else {
      remove(this.closedSections, (item) => item === sectionId);
    }
    if (ExerciseUI.userGroup) {
      ExerciseUI.userGroup.set('closedGridSections', this.closedSections.join(','));
    }
    ExerciseUI.userGroupStore.update({ids: {userGroupId: ExerciseUI.userGroup.id}, data: {closed_grid_sections: this.closedSections.join(',')}})
  }

  @autobind sectionIsOpen(sectionId) {
    return this.closedSections?.indexOf(sectionId) === -1;
  }

  @autobind getGridItemWorksheets(gridItem) {
    ExerciseUI.gridItemWorksheetStore.getIndex({queryStrings: {filters: {grid_item_id: gridItem.id}, limit: Constants.WORKSHEETS_PER_SECTION, page: gridItem.gridItemState.currentPage, worksheet_filters: this.worksheetFilters, worksheet_sort: this.sortParameter, show_all: !this.props.showFilters}}, (resp) => this.afterChangeGridItemPage(gridItem.id, resp));
  }

  @autobind onIncrementGridItemPage(increment, gridItem) {
    this.gridSectionIsLoading[gridItem.id] = true;
    gridItem.gridItemState.setPage(gridItem.gridItemState.currentPage + increment);
    this.getGridItemWorksheets(gridItem);
  }

  @autobind afterChangeGridItemPage(gridItemId, resp) {
    this.gridSectionIsLoading[gridItemId] = false;
    if (ExerciseUI.gridItemStore.hasIndexData) {
      ExerciseUI.gridItemStore.indexData.find(item => item.id === gridItemId)['gridItemWorksheets'] = resp.map(item => deserialize(GridItemWorksheet, item));
    }
  }

  @autobind loadTags() {
    AppUI.tagCategoryStore.getIndex({ids: {groupId: AppUI.user.currentUserGroup?.groupId}}, this.afterLoadTags);
  }

  @autobind afterLoadTags(resp) {
    AppUI.tagCategoryStore.setIndexData(resp);
    this.getGrid();
  }

  @autobind displayTagCategory(tagCategory) {
    return (this.props.route?.name === 'videos.index' && tagCategory.displayOnVideoWorksheets) || (this.props.route?.name === 'podcast.index' && tagCategory.displayOnPodcastWorksheets) || (tagCategory.displayOnHomeWorksheets && this.props.route?.name === AppUI.site.signedInHomePageRoute)
  }

  @computed get showProgressTag() {
    const progressCategory = AppUI.tagCategoryStore.indexData.find(item => item.slug === 'progress');
    if (isBlank(progressCategory)) { return }
    return this.displayTagCategory(progressCategory);
  }

  @computed get filteredTagCategories() {
    return AppUI.tagCategoryStore.indexData?.filter(item => item.published && this.displayTagCategory(item));
  }

  @computed get hasSelectedFilterTags() {
    return  (this.showProgressTag && isPresent(AppUI.user.currentUserGroup.worksheetProgressTags)) || (isPresent(AppUI.user.currentUserGroup.worksheetTagIds) && some(this.filteredTagCategories.map(tagCategory => some(tagCategory.tags.map(tag => AppUI.user.currentUserGroup.worksheetTagIds.split(',').indexOf(tag.id) !== -1)))));
  }

  render () {
    return (
      <Wrapper
        paddingTop={AppUI.layout.isMobile ? '15px' : null}
        id='page-top'
      >
        {
          AppUI.pageStore.hasShowData &&
          <ContentWrapper
            dangerouslySetInnerHTML={{ __html: AppUI.pageStore.showData.content }}
            borderRadius={AppUI.layout.isMobile ? '0' : '10px'}
          />
        }
        {
          this.props.showFilters && ExerciseUI.site.showWorksheetFilter &&
            <WorksheetFilter
              onUpdateFilter={() => this.getGrid({forceLoad: true})}
              onSearchQueryChange={this.onSearchQueryChange}
              searchQuery={this.searchQuery}
              toggleSort={this.toggleSort}
              sort={this.sort}
              routeName={this.props.route?.name}
              showProgressTag={this.showProgressTag}
              filteredTagCategories={this.filteredTagCategories}
              hideSort={this.useGridLayout}
            />
        }
        {
          this.useGridLayout && !this.gridIsLoaded &&
            <Spinner
              background={ExerciseUI.site.accentColor}
            />
        }
        {
          this.useGridLayout && ExerciseUI.gridItemStore.indexData.map((gridItem) => {
            if (gridItem.isVisibleToUser && (gridItem.worksheetsCount > 0 || (gridItem.otherPosts && ExerciseUI.worksheetStore.hasIndexData) || (gridItem.mostRecent && ExerciseUI.recentWorksheetStore.hasIndexData))) {
              return (
                <Section
                  key={gridItem.id}
                >
                  <Name
                    onClick={() => this.onToggleSection(gridItem.id)}
                    id={`${gridItem.id}-page-top`}
                  >
                    <div>
                      <i className={`fa fa-angle-${this.sectionIsOpen(gridItem.id) ? 'down' : 'right'}`} />
                      {gridItem.name}
                    </div>
                    {
                      !gridItem.isAutomatic &&
                        <SectionDetails>
                          {
                            ExerciseUI.user.anyAdmin && AppUI.user.currentUserGroup?.groupId &&
                            <AdminLink>
                              <Link
                                to={{
                                  name: 'creator.grid_items.edit',
                                  params: {gridItemId: gridItem.id, groupId: AppUI.user.currentUserGroup?.groupId}
                                }}
                              >
                                Admin Link
                              </Link>
                            </AdminLink>
                          }
                          {
                            isPresent(gridItem.filteredWorksheetsCount) &&
                            `${gridItem.filteredWorksheetsCount} Post${gridItem.filteredWorksheetsCount === 0 ? '' : 's'}`
                          }
                        </SectionDetails>
                    }
                  </Name>
                  {
                    this.sectionIsOpen(gridItem.id) &&
                      <WorksheetWrapper
                        id={gridItem.id}
                        minHeight={gridItem.gridItemWorksheets.length > 3 ? 600 : null}
                        marginTop={AppUI.layout.isMobile ? '15px' : null}
                      >
                        {
                          !gridItem.isAutomatic && !this.gridSectionIsLoading[gridItem.id] && gridItem.gridItemWorksheets.map((gridItemWorksheet, index) => {
                            return (
                              <WorksheetGridItem
                                onUpdateFilter={() => this.getGrid({forceLoad: true})}
                                width={this.gridItemWidth}
                                key={gridItemWorksheet.id}
                                worksheet={gridItemWorksheet.worksheet}
                                gridLayout={this.useGridLayout}
                              />
                            )
                          })
                        }
                        {
                          gridItem.mostRecent && ExerciseUI.recentWorksheetStore.hasIndexData && ExerciseUI.recentWorksheetStore.indexData.map((item, index) => {
                            return (
                              <WorksheetGridItem
                                onUpdateFilter={() => this.getGrid({forceLoad: true})}
                                width={this.gridItemWidth}
                                key={item.id}
                                worksheet={item}
                                gridLayout={this.useGridLayout}
                              />
                            )
                          })
                        }
                        {
                          gridItem.otherPosts && ExerciseUI.worksheetStore.indexData.map((item, index) => {
                            return (
                              <WorksheetGridItem
                                onUpdateFilter={() => this.getGrid({forceLoad: true})}
                                width={this.gridItemWidth}
                                key={item.id}
                                worksheet={item}
                                gridLayout={this.useGridLayout}
                              />
                            )
                          })
                        }
                        {
                          this.gridSectionIsLoading[gridItem.id] && [...Array(6)].map((item, index) => {
                            return (
                              <WorksheetGridItem
                                onUpdateFilter={() => this.getGrid({forceLoad: true})}
                                width={this.gridItemWidth}
                                key={`placeholder-${index}`}
                                gridLayout={this.useGridLayout}
                              />
                            )
                          })
                        }
                      </WorksheetWrapper>
                  }
                  {
                    !gridItem.isAutomatic && this.sectionIsOpen(gridItem.id) && gridItem.filteredWorksheetsCount > Constants.WORKSHEETS_PER_SECTION &&
                      <Paginator
                        onClickScrollToTop={AppUI.layout.isMobile}
                        pageTop={`${gridItem.id}-page-top`}
                        hideInput
                        onPagination={(increment) => this.onIncrementGridItemPage(increment, gridItem)}
                        totalCount={gridItem.filteredWorksheetsCount}
                        currentPage={gridItem.gridItemState.currentPage}
                        limit={Constants.WORKSHEETS_PER_SECTION}
                      />
                  }
                  {
                    gridItem.otherPosts && this.sectionIsOpen(gridItem.id) &&
                      <Paginator
                        onClickScrollToTop={AppUI.layout.isMobile}
                        store={ExerciseUI.worksheetStore}
                        pageTop={`${gridItem.id}-page-top`}
                        queryStrings={{excluded_worksheet_ids: this.recentWorksheetIds, show_all: true}}
                      />
                  }
                </Section>
              )
            }
          })
        }
        {
          !this.useGridLayout && ExerciseUI.worksheetStore.hasIndexData &&
            <Section>
              <WorksheetWrapper
                marginTop={AppUI.layout.isMobile ? '15px' : null}
              >
                {
                  ExerciseUI.worksheetStore.indexData.map((item, index) => {
                    return (
                      <WorksheetGridItem
                        onUpdateFilter={() => this.getGrid({forceLoad: true})}
                        width={this.gridItemWidth}
                        key={item.id}
                        worksheet={item}
                        gridLayout={this.useGridLayout}
                      />
                    )
                  })
                }
              </WorksheetWrapper>
              {
                AppUI.layout.isMobile &&
                  <Paginator
                    onClickScrollToTop
                    pageTop='top'
                    store={ExerciseUI.worksheetStore}
                  />
              }
            </Section>
        }
        {
          !this.useGridLayout && ExerciseUI.worksheetStore.requestCounter > 0 &&
            <Spinner
              background={ExerciseUI.site.accentColor}
            />
        }
        {
          ExerciseUI.site.showWorksheetFilter && this.gridIsLoaded && !this.useGridLayout && ExerciseUI.worksheetStore.indexData?.length === 0 &&
            <Text center>Sorry, we don't have any post that match these filters!</Text>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(GridLayout);
