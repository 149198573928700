import React from 'react';
import { Redirect, Route } from 'react-router';
import RedirectToHome from 'pages/main/redirect_to_home';
import { ComponentForDefaultOrWhitelabelSite } from 'utils/component_for_default_or_whitelabel_site';

// app
import Onboarding from 'components/onboarding/onboarding';
import App from 'pages/app';
import ProfileHome from 'components/profile/profile_home';
import Settings from 'components/user/settings';
import Faq from 'pages/main/faq';
import HelpCenter from 'pages/main/help_center';
import FaqCategoryShow from 'components/faq_category/faq_category_show';
import FaqItemShow from 'components/faq_item/faq_item_show';
import More from 'components/profile/more';
import Contact from 'pages/main/contact';
import AccountDeletion from 'pages/main/account_deletion';
import SettingsUserProfile from 'components/user/settings_user_profile';
import SettingsDeck from 'components/user/settings_deck';
import SettingsClearProgress from 'components/user/settings_clear_progress';
import SettingsStreakRepair from 'components/user/settings_streak_repair';
import SettingsUserImage from 'components/user/settings_user_image';
import SettingsUserNotifications from 'components/user/settings_user_notifications';
import SettingsBilling from 'components/user/settings_billing';
import SettingsAffiliate from 'components/user/settings_affiliate';
import SettingsPassword from 'components/user/settings_password';
import SettingsMemberships from 'components/user/settings_memberships';
import SettingsPatreon from 'components/user/settings_patreon';
import SettingsCancel from 'components/user/settings_cancel';
import SettingsCancelWhitelabel from 'components/user/settings_cancel_whitelabel';
import SettingsRssFeed from 'components/user/settings_rss_feed';
import SettingsPrivacy from 'components/user/settings_privacy';
import DeckShow from 'components/deck/deck_show';
import TreeNodeShow from 'components/tree_node/tree_node_show';
import ReviewProgress from 'components/review/review_progress';
import ReviewShow from 'components/review/review_show';
import Review from 'components/review/review';
import MembershipIndex from 'components/membership/membership_index';
import MembershipChange from 'components/membership/membership_change';
import Checkout from 'components/checkout/checkout';
import SubscriptionChangeShow from 'components/subscription_change/subscription_change_show';
import ReviewHome from 'components/review/review_home';
import VocabHome from 'components/vocab/vocab_home';
import ThankYou from 'components/checkout/thank_you';
import Stories from 'components/stories/stories';
import StoriesProgress from 'components/stories/stories_progress';
import StoriesHome from 'components/stories/stories_home';
import Vocab from 'components/vocab/vocab';
import VocabWord from 'components/vocab/vocab_word';
import VocabCreate from 'components/vocab/vocab_create';
import Trivia from 'components/trivia/trivia';
import TriviaHome from 'components/trivia/trivia_home';
import TriviaProgress from 'components/trivia/trivia_progress';
import Landing from 'components/landing/landing';
import VocabProgress from 'components/vocab/vocab_progress';
import Maintenance from 'pages/main/maintenance';
import Discord from 'pages/main/discord';
import AssignMembershipGroup from 'components/membership/assign_membership_group';

// builder
import Builder from 'pages/builder/builder';
import Translation from 'pages/builder/translation';
import Concept from 'pages/builder/concept';
import DeckNav from 'pages/builder/deck';
import ReferrerOptionIndex from 'pages/builder/referrer_options/referrer_option_index';
import SiteNoticeIndex from 'pages/builder/site_notices/site_notice_index';
import BannerNoticeIndex from 'pages/builder/banner_notices/banner_notice_index';
import BannerNoticeEdit from 'pages/builder/banner_notices/banner_notice_edit';
import SiteNoticeEdit from 'pages/builder/site_notices/site_notice_edit';
import ContactMessageIndex from 'pages/builder/contact_messages/contact_message_index';
import ContactMessageShow from 'pages/builder/contact_messages/contact_message_show';
import WordIndex from 'pages/builder/words/word_index';
import WordEdit from 'pages/builder/words/word_edit';
import SentenceIndex from 'pages/builder/sentences/sentence_index';
import SentenceEdit from 'pages/builder/sentences/sentence_edit';
import TargetIndex from 'pages/builder/targets/target_index';
import TargetEdit from 'pages/builder/targets/target_edit';
import ConceptIndex from 'pages/builder/concepts/concept_index';
import ConceptCategoryIndex from 'pages/builder/concept_categories/concept_category_index';
import ConceptEdit from 'pages/builder/concepts/concept_edit';
import SourceIndex from 'pages/builder/sources/source_index';
import TranslationSourceIndex from 'pages/builder/translation_sources/translation_source_index';
import SourceEdit from 'pages/builder/sources/source_edit';
import DeckRulebookIndex from 'pages/builder/deck_rulebooks/deck_rulebook_index';
import DeckRulebookEdit from 'pages/builder/deck_rulebooks/deck_rulebook_edit';
import DeckEdit from 'pages/builder/decks/deck_edit';
import User from 'pages/builder/user';
import Subscription from 'pages/builder/subscription';
import DeckIndex from 'pages/builder/decks/deck_index';
import InterjectionIndex from 'pages/builder/interjections/interjection_index';
import InterjectionEdit from 'pages/builder/interjections/interjection_edit';
import UserIndex from 'pages/builder/users/user_index';
import UserEdit from 'pages/builder/users/user_edit';
import BuilderChatIndex from 'pages/builder/chats/chat_index';
import BuilderChatShow from 'pages/builder/chats/chat_show';
import SessionIndex from 'pages/builder/sessions/session_index';
import ConceptCategoryEdit from 'pages/builder/concept_categories/concept_category_edit';
import CardTypeIndex from 'pages/builder/card_types/card_type_index';
import CardTypeEdit from 'pages/builder/card_types/card_type_edit';
import UserExerciseIndex from 'pages/builder/user_exercises/user_exercise_index';
import BuilderNotificationIndex from 'pages/builder/notifications/notification_index';
import LevelIndex from 'pages/builder/levels/level_index';
import LevelEdit from 'pages/builder/levels/level_edit';
import RatingIndex from 'pages/builder/ratings/rating_index';
import RatingEdit from 'pages/builder/ratings/rating_edit';
import RatingTypeIndex from 'pages/builder/rating_types/rating_type_index';
import RatingTypeEdit from 'pages/builder/rating_types/rating_type_edit';
import TreeModuleEdit from 'pages/builder/tree_modules/tree_module_edit';
import TreeEdit from 'pages/builder/trees/tree_edit';
import TreeModuleIndex from 'pages/builder/tree_modules/tree_module_index';
import TreeIndex from 'pages/builder/trees/tree_index';
import TreeNodeEdit from 'pages/builder/tree_nodes/tree_node_edit';
import ConceptStudio from 'pages/builder/tree_nodes/concept_studio';
import CampaignMessageIndex from 'pages/builder/campaign_messages/campaign_message_index';
import CampaignMessageShow from 'pages/builder/campaign_messages/campaign_message_show';
import SendNotification from 'pages/builder/notifications/send_notification';
import Outreach from 'pages/builder/outreach';
import CampaignTypeIndex from 'pages/builder/campaign_types/campaign_type_index';
import CampaignTypeEdit from 'pages/builder/campaign_types/campaign_type_edit';
import CampaignEdit from 'pages/builder/campaigns/campaign_edit';
import BlogEntryIndex from 'pages/builder/blog_entries/blog_entry_index';
import BlogEntryEdit from 'pages/builder/blog_entries/blog_entry_edit';
import BuilderForumIndex from 'pages/builder/forums/forum_index';
import ForumEdit from 'pages/builder/forums/forum_edit';
import WordTypeIndex from 'pages/builder/word_types/word_type_index';
import WordTypeEdit from 'pages/builder/word_types/word_type_edit';
import SubscriptionIndex from 'pages/builder/subscriptions/subscription_index';
import SubscriptionEdit from 'pages/builder/subscriptions/subscription_edit';
import BuilderMembershipIndex from 'pages/builder/memberships/membership_index';
import MembershipEdit from 'pages/builder/memberships/membership_edit';
import MembershipTypeIndex from 'pages/builder/membership_types/membership_type_index';
import MembershipTypeEdit from 'pages/builder/membership_types/membership_type_edit';
import MembershipGroupIndex from 'pages/builder/membership_groups/membership_group_index';
import MembershipGroupEdit from 'pages/builder/membership_groups/membership_group_edit';
import UserDeckIndex from 'pages/builder/user_decks/user_deck_index';
import ErrorLogIndex from 'pages/builder/error_logs/error_log_index';
import MeetupsEdit from 'pages/builder/meetups/meetups_edit';
import PromotionIndex from 'pages/builder/promotions/promotion_index';
import PromotionEdit from 'pages/builder/promotions/promotion_edit';
import PaymentEdit from 'pages/builder/payments/payment_edit';
import PaymentIndex from 'pages/builder/payments/payment_index';
import VideoClipIndex from 'pages/builder/video_clips/video_clip_index';
import VideoClipEdit from 'pages/builder/video_clips/video_clip_edit';
import BuilderDashboard from 'pages/builder/dashboard/dashboard';
import UsersDashboard from 'pages/builder/dashboard/users_dashboard';
import CampaignMessagesDashboard from 'pages/builder/dashboard/campaign_messages_dashboard';
import PaymentsDashboard from 'pages/builder/dashboard/payments_dashboard';
import SubscriptionsDashboard from 'pages/builder/dashboard/subscriptions_dashboard';
import DidacticsDashboard from 'pages/builder/dashboard/didactics_dashboard';
import DecksDashboard from 'pages/builder/dashboard/decks_dashboard';
import Analytics from 'components/analytics/analytics';
import ExercisesDashboard from 'pages/builder/dashboard/exercises_dashboard';
import LevelsDashboard from 'pages/builder/dashboard/levels_dashboard';
import AffiliatePayoutIndex from 'pages/builder/affiliate_payouts/affiliate_payout_index';
import AffiliatePayoutShow from 'pages/builder/affiliate_payouts/affiliate_payout_show';
import InvitationIndex from 'pages/builder/invitations/invitation_index';
import ChallengeIndex from 'pages/builder/challenges/challenge_index';
import BuilderLanguageIndex from 'pages/builder/languages/language_index';
import LanguageEdit from "pages/builder/languages/language_edit";
import UserSubmissionIndex from 'pages/builder/cards/user_submission_index';
import PaymentIntentIndex from 'pages/builder/payment_intents/payment_intent_index';
import TranslationSourceEdit from 'pages/builder/translation_sources/translation_source_edit';
import SiteEdit from 'pages/builder/sites/site_edit';
import SiteIndex from 'pages/builder/sites/site_index';
import Site from 'pages/builder/site';
import SiteReleaseIndex from 'pages/builder/site_releases/site_release_index';
import SiteReleaseEdit from 'pages/builder/site_releases/site_release_edit';
import SentenceSubmissionIndex from 'pages/builder/sentence_submissions/sentence_submission_index';
import FaqCategoryIndex from 'pages/builder/faq_categories/faq_category_index';
import FaqCategoryEdit from 'pages/builder/faq_categories/faq_category_edit';
import FaqItemEdit from 'pages/builder/faq_items/faq_item_edit';
import GroupIndex from 'pages/builder/groups/group_index';
import GroupEdit from 'pages/builder/groups/group_edit';
import { PageEdit, PageIndex, PageShow, PageShowBySlug } from 'pages/builder/pages';
import { JobEdit, JobIndex } from 'pages/builder/jobs';

// creator
import Creator from 'pages/creator/creator';
import Producer from 'pages/creator/producer/producer';
import ProducerNavigation from 'pages/creator/producer/producer_navigation';
import ProducerConcept from 'pages/creator/producer/producer_concept';
import VideoClips from 'pages/creator/video_clips/video_clips';
import RecordingSessionsIndex from 'pages/creator/recording_sessions/recording_sessions_index';
import RecordingSessionsShow from 'pages/creator/recording_sessions/recording_sessions_show';
import Sentences from 'pages/creator/sentences/sentences';
import CreatorSentenceEdit from 'pages/creator/sentences/sentence_edit';
import ExerciseTypeIndex from 'pages/creator/exercise_types/exercise_type_index';
import PatreonUsersIndex from 'pages/creator/patreon_users/patreon_users_index';
import PatreonUsersEdit from 'pages/creator/patreon_users/patreon_users_edit';
import UsersIndex from 'pages/creator/users/users_index';
import AdminsIndex from 'pages/creator/users/admins_index';
import UsersEdit from 'pages/creator/users/users_edit';
import WorksheetEdit from 'pages/creator/worksheets/worksheet_edit';
import VocabEdit from 'pages/creator/worksheets/vocab_edit';
import CreatorWorksheetIndex from 'pages/creator/worksheets/worksheet_index';
import CreatorExerciseIndex from 'pages/creator/exercises/exercise_index';
import CreatorGroupShow from 'pages/creator/groups/group_show';
import ExerciseEdit from 'pages/creator/exercises/exercise_edit';
import TagsIndex from 'pages/creator/tags/tags_index';
import CreatorWordIndex from 'pages/creator/words/word_index';
import {
  SiteLanguageEdit,
  SiteLayout,
  SiteMembershipEdit,
  SiteMembershipGroupEdit,
  SiteMembershipGroupIndex,
  SiteNavigation,
  SitePageEdit,
  SitePageIndex
} from 'pages/creator/site';
import { SiteMembershipTypeEdit, SiteMembershipTypeIndex } from 'pages/creator/membership_types';
import { SiteSubscriptionEdit, SiteSubscriptionIndex } from 'pages/creator/subscriptions';
import SiteCampaignTypeIndex from 'pages/creator/campaign_types/campaign_type_index';
import SiteCampaignTypeEdit from 'pages/creator/campaign_types/campaign_type_edit';
import SiteCampaignEdit from 'pages/creator/campaigns/campaign_edit';
import EmailIndex from 'pages/creator/email/email_index';
import GridItemIndex from 'pages/creator/grid_items/grid_item_index';
import GridItemEdit from 'pages/creator/grid_items/grid_item_edit';
import PodcastEpisodeIndex from 'pages/creator/podcast_episodes/podcast_episode_index';
import PodcastEpisodeEdit from 'pages/creator/podcast_episodes/podcast_episode_edit';
import PodcastIndex from 'pages/creator/podcasts/podcast_index';
import PodcastEdit from 'pages/creator/podcasts/podcast_edit';
import CreateWorksheetComments from 'pages/creator/worksheets/worksheet_comments';
import PaymentMigrationIndex from 'pages/creator/payment_migration/payment_migration_index';
import DownloadLinkTypeIndex from 'pages/creator/download_link_types/download_link_type_index';
import DownloadLinkTypeEdit from 'pages/creator/download_link_types/download_link_type_edit';

// translator
import Translator from 'pages/translator/translator';
import TranslatorHome from 'pages/translator/translator_home';
import TranslatorWords from 'pages/translator/translator_words';
import TranslatorSentencesIndex from 'pages/translator/translator_sentences_index';
import TranslatorDecksIndex from 'pages/translator/translator_decks_index';
import TranslatorDecksShow from 'pages/translator/translator_decks_show';
import TranslatorUsers from 'pages/translator/translator_users';
import TranslatorUser from 'pages/translator/translator_user';

// classroom
import BackendClassroom from 'pages/classroom/classroom';
import ClassroomDashboard from 'pages/classroom/classroom_dashboard';
import ClassroomSettings from 'pages/classroom/classroom_settings';
import ClassroomStudents from 'pages/classroom/classroom_students';

// not for app
import UserResetPassword from 'components/user/user_reset_password';
import GroupShow from 'components/group/group_show';
import WorksheetShow from 'components/worksheet/worksheet_show';
import WorksheetComments from 'components/worksheet/worksheet_comments';
import ExerciseShow from 'components/exercise/exercise_show';
import WorksheetVocab from 'components/worksheet/worksheet_vocab';
import GroupInfo from 'components/group/group_info';
import GroupInfo2 from 'components/group/group_info2';
import ExerciseIndex from 'components/exercise/exercise_index';
import WorksheetIndex from 'components/worksheet/worksheet_index';
import Forum from 'pages/main/forum';
import ForumIndex from 'components/forum/forum_index';
import ForumShow from 'components/forum/forum_show';
import ForumThreadShow from 'components/forum_thread/forum_thread_show';
import ForumThreadCreate from 'components/forum_thread/forum_thread_create';
import Chat from 'pages/main/chat';
import ChatEdit from 'components/chat/chat_edit';
import ChatShow from 'components/chat/chat_show';
import Feed from 'pages/main/feed';
import FeedEntryShow from 'components/feed/feed_entry_show';
import AppListing from 'pages/main/app_listing';
import Privacy from 'pages/main/privacy';
import Partners from 'pages/main/partners';
import Legal from 'pages/main/legal';
import Terms from 'pages/main/terms';
import Team from 'pages/main/team';
import JobsIndex from 'components/jobs/jobs_index';
import JobsShow from 'components/jobs/jobs_show';
import SiteReleases from 'pages/main/site_releases';
import Classroom from 'components/classroom/classroom';
import ClassroomShow from 'components/classroom/classroom_show';
import ClassroomProgress from 'components/classroom/classroom_progress';
import Grammar from 'components/grammar/grammar';
import GrammarHome from 'components/grammar/grammar_home';
import GrammarIndex from "components/grammar/grammar_index";
import ExerciseRatingsIndex from "pages/creator/ratings/exercise_ratings_index";
import VideoWorksheetIndex from "components/worksheet/video_worksheet_index";
import PodcastWorksheetIndex from "components/worksheet/podcast_worksheet_index";
import DownloadLinkIndex from 'components/download_link/download_link_index';
import BlockNotifications from 'pages/main/block_notifications';
import CustomTabIndex from "pages/creator/site/custom_tab_index";
import CustomTabEdit from "pages/creator/site/custom_tab_edit";
import CustomTabShow from "pages/creator/site/custom_tab_show";
import PatreonConnect from "components/membership/patreon_connect";
import WorksheetInfo from "components/worksheet/worksheet_info";

const routes = (
  <Route
    name="app"
    component={App}
  >
    <Route
      path="/user/password/edit"
      name="user.reset_password"
      component={UserResetPassword}
      signedOutRoute
    />
    <Route
      path="/trivia/decks/:deckId/user_decks/:userDeckId"
      name="trivia.decks.show"
      component={DeckShow}
    />
    <Route
      component={Landing}
      name="landing"
      path="/"
      signedOutRoute
    />
    <Route
      component={Maintenance}
      name="maintenance"
      path="/offline"
    />
    <Route
      component={JobsIndex}
      name="jobs"
      path="/jobs"
      signedOutRoute
    />
    <Route
      component={JobsShow}
      name="jobs.show"
      path="/jobs/:jobId"
      signedOutRoute
    />
    <Route
      component={PageShow}
      name="whitelabel.home"
      path="/pages/home"
      allowWhiteLabel
    />
    <Route
      name="home"
      path="/redirect"
      component={RedirectToHome}
    />
    <Route
      name="translator"
      component={Translator}
    >
      <Route
        component={TranslatorHome}
        name="translator.home"
        path="/translator/:languageId/home"
      />
      <Route
        component={TranslatorDecksIndex}
        name="translator.decks_index"
        path="/translator/:languageId/decks"
      />
      <Route
        component={TranslatorDecksShow}
        name="translator.decks_show"
        path="/translator/:languageId/decks/:deckId"
      />
      <Route
        component={TranslatorSentencesIndex}
        name="translator.sentences_index"
        path="/translator/:languageId/sentences"
      />
      <Route
        component={TranslatorWords}
        name="translator.words"
        path="/translator/:languageId/words"
      />
      <Route
        component={TranslatorUsers}
        name="translator.users"
        path="/translator/:languageId/translators"
      />
      <Route
        component={TranslatorUser}
        name="translator.user"
        path="/translator/:languageId/translators/:userId"
      />
      <Route
        component={ForumThreadCreate}
        name="translator.forum_threads.create"
        path="/translator/:languageId/threads/create"
      />
    </Route>
    <Route
      name="classroom"
      component={Classroom}
    >
      <Route
        component={ClassroomShow}
      >
        <Route
          component={ForumShow}
          name="classroom.show"
          path="/classroom/:groupId"
        />
        <Route
          component={ForumThreadCreate}
          name="classroom.forum_threads.create"
          path="/classroom/:groupId/threads/create"
        />
        <Route
          component={ForumThreadShow}
          name="classroom.forum_threads.show"
          path="/classroom/:groupId/threads/:forumThreadId"
        />
      </Route>
      <Route
        component={ClassroomProgress}
        name="classroom.progress"
        path="/classroom/:groupId/progress"
      />
    </Route>
    <Route
      name="trivia"
      component={Trivia}
    >
      <Route
        path="/trivia"
        name="trivia.home"
        component={TriviaHome}
      />
      <Route
        path="/trivia/progress"
        name="trivia.progress"
        component={TriviaProgress}
      />
    </Route>
    <Route
      component={VocabHome}
    >
      <Route
        name="vocab.home"
        path="/vocab/words/page/:page"
        component={Vocab}
      />
      <Route
        name="vocab.progress"
        path="/vocab/progress"
        component={VocabProgress}
      />
      <Route
        name="vocab.word"
        path="/vocab/words/:wordId"
        component={VocabWord}
        allowWhiteLabel
      />
      <Route
        name="vocab.create"
        path="/vocab/create"
        component={VocabCreate}
      />
    </Route>
    <Route
      component={Stories}
    >
      <Route
        name="stories.home"
        path="/stories/home"
        component={StoriesHome}
      />
      <Route
        name="stories.show"
        path="/topics/:treeNodeId"
        component={TreeNodeShow}
      />
      <Route
        name="stories.progress"
        path="/stories/progress"
        component={StoriesProgress}
      />
    </Route>
    <Route
      component={Review}
      name='reviews'
    >
      <Route
        name='reviews.decks'
        path='/reviews/decks'
        component={ReviewHome}
      />
      <Route
        name='reviews.home'
        path='/reviews/home'
        component={ReviewHome}
      />
      <Route
        name='reviews.archive'
        path='/reviews/archive'
        component={ReviewHome}
      />
      <Route
        name='reviews.index'
        path='/reviews/list/page/:page'
        component={ReviewHome}
      />
      <Route
        name='reviews.progress'
        path='/reviews/progress'
        component={ReviewProgress}
      />
      <Route
        name='reviews.show'
        path='/reviews/card/:cardId/page/:page'
        component={ReviewShow}
      />
      <Route
        name='reviews.create'
        path='/reviews/create'
        component={ReviewHome}
      />
    </Route>
    <Route
      component={Grammar}
    >
      <Route
        component={GrammarHome}
        name="grammar"
        path="/grammar"
      />
      <Route
        name="grammar.index"
        path="/grammar/index"
        component={GrammarIndex}
      />
      <Route
        name="grammar.show"
        path="/grammar/:grammarNodeId"
        component={GrammarHome}
      />
      <Route
        name="grammar.show.slug"
        path="/grammar/:grammarNodeId/:slug"
        component={GrammarHome}
      />
    </Route>
    <Route
      name='grammar.decks.show'
      path='/grammar/:treeNodeId/decks/:deckId'
      component={DeckShow}
    />
    <Route
      name="vocab.decks.show"
      path="/vocab/decks/:deckId"
      component={DeckShow}
    />
    <Route
      name="triad.decks.show"
      path="/words/:wordId/decks/:deckId"
      component={DeckShow}
    />
    <Route
      name="trivia.tree_nodes.decks.show"
      path="/trivia/:treeNodeId/decks/:deckId"
      component={DeckShow}
    />
    <Route
      name="trivia.tree_nodes.decksWithOpponent.show"
      path="/trivia/:treeNodeId/decks/:deckId/opponents/:opponentId"
      component={DeckShow}
    />
    <Route
      name="onboarding"
      path="/onboarding/deck/:deckId"
      component={Onboarding}
      signedOutRoute
      allowWhiteLabel
    />
    <Route
      name="onboarding"
      path="/onboarding"
      component={Onboarding}
      signedOutRoute
      allowWhiteLabel
    />
    <Route
      name="onboarding.registration"
      path="/onboarding/registration"
      component={Onboarding}
      signedOutRoute
      allowWhiteLabel
    />
    <Route
      name="onboarding.sign_in"
      path="/onboarding/sign_in"
      component={Onboarding}
      signedOutRoute
      allowWhiteLabel
    />
    <Route
      name="onboarding.password"
      path="/onboarding/password"
      component={Onboarding}
      allowWhiteLabel
    />
    <Route
      name="onboarding.migration"
      path="/onboarding/migration"
      component={Onboarding}
      allowWhiteLabel
    />
    <Route
      name="onboardingLanguage"
      path="/onboarding/:languageId"
      component={Onboarding}
      signedOutRoute
      allowWhiteLabel
    />
    <Route
      name="onboarding.patreon"
      path="/patreon"
      component={PatreonConnect}
      allowWhiteLabel
    />
    <Route
      name="onboardingLanguageWithGroup"
      path="/onboarding/:languageId/group/:groupId"
      component={Onboarding}
      signedOutRoute
      allowWhiteLabel
    />
    <Route
      name="profile"
      path="/profile"
      component={ProfileHome}
      allowWhiteLabel
    />
    <Route
      name="memberships.index"
      path="/memberships"
      component={MembershipIndex}
      signedOutRoute
      allowWhiteLabel
    />
    <Route
      name="memberships.change"
      path="/memberships/change"
      component={MembershipChange}
      allowWhiteLabel
    />
    <Route
      name="memberships.join"
      path="/join"
      component={MembershipIndex}
      signedOutRoute
      allowWhiteLabel
    />
    <Route
      name="memberships.thank_you"
      path="/memberships/thank_you"
      component={ThankYou}
      allowWhiteLabel
    />
    <Route
      name="memberships.create"
      path="/memberships/:membershipId"
      component={Checkout}
      allowWhiteLabel
    />
    <Route
      name="subscription.change"
      path="/subscription_change"
      component={SubscriptionChangeShow}
      allowWhiteLabel
    />
    <Route
      name="worksheets.vocab.word"
      path="posts/:worksheetId/vocab/:wordId"
      component={VocabWord}
      allowWhiteLabel
    />
    {
      [{name: 'worksheets', path: "posts/:worksheetId"}, {name: 'videos', path: "videos/:worksheetId"}, {name: 'podcast', path: "podcast/:worksheetId"}].map((item, index) => {
        return (
          <Route
            name={`${item.name}.show`}
            path={item.path}
            component={WorksheetShow}
            key={index}
            allowWhiteLabel
          >
            <Route
              name={`${item.name}.exercises.show`}
              path="exercises/:exerciseId"
              component={ExerciseShow}
              allowWhiteLabel
            />
            {
              item.name === 'worksheets' &&
                <Route
                  name={`exercises.show`}
                  path="exercises/:exerciseId/:namespace"
                  component={ExerciseShow}
                />
            }
            <Route
              name={`${item.name}.vocab.show`}
              path="vocab"
              component={WorksheetVocab}
              allowWhiteLabel
            />
            <Route
              name={`${item.name}.info.show`}
              path="info"
              component={WorksheetInfo}
              allowWhiteLabel
            />
            <Route
              name={`${item.name}.comments.show`}
              path="comments"
              component={WorksheetComments}
              allowWhiteLabel
            />
            <Route
              name={`${item.name}.download_links.index`}
              path="download_links"
              component={DownloadLinkIndex}
              allowWhiteLabel
            />
            <Route
              name={`${item.name}.custom_tab.show`}
              path="custom_tab/:customTabId"
              component={CustomTabShow}
              allowWhiteLabel
            />
          </Route>
        )
      })
    }

    <Route
      name="groups.show"
      path="/groups/:groupId"
      component={GroupShow}
      allowWhiteLabel
    >
      <Route
        name="worksheets.by_filter"
        path="/posts/filter/:filter"
        component={WorksheetIndex}
        allowWhiteLabel
      />
      <Route
        name="worksheets.grid_with_sections"
        path="/grid_with_sections"
        component={WorksheetIndex}
        allowWhiteLabel
      />
      <Route
        name="worksheets.index"
        path="/posts"
        component={WorksheetIndex}
        allowWhiteLabel
      />
      <Route
        name="videos.index"
        path="/videos"
        component={VideoWorksheetIndex}
        allowWhiteLabel
      />
      <Route
        name="podcast.index"
        path="/podcast"
        component={PodcastWorksheetIndex}
        allowWhiteLabel
      />
      <Route
        name="exercises.index"
        path="/exercises"
        component={ExerciseIndex}
        allowWhiteLabel
      />
      <Route
        name="groups.info"
        path="/info"
        component={GroupInfo}
        allowWhiteLabel
      />
      <Route
        name="groups.info2"
        path="/info2"
        component={GroupInfo2}
        allowWhiteLabel
      />
    </Route>
    <Route
      name="settings"
      path="/settings"
      component={Settings}
      allowWhiteLabel
    >
      <Route
        name='settings.user_profile'
        path='user_profile'
        component={SettingsUserProfile}
        allowWhiteLabel
      />
      <Route
        name='settings.deck'
        path='deck_settings'
        component={SettingsDeck}
      />
      <Route
        name='settings.clear_progress'
        path='clear_progress'
        component={SettingsClearProgress}
      />
      <Route
        name='settings.streak_repair'
        path='streak_repair'
        component={SettingsStreakRepair}
      />
      <Route
        name='settings.user_image'
        path='user_image'
        component={SettingsUserImage}
        allowWhiteLabel
      />
      <Route
        signedOutRoute
        name='settings.user_notifications'
        path='user_notifications'
        component={SettingsUserNotifications}
      />
      <Route
        name='settings.billing'
        path='billing'
        component={SettingsBilling}
        allowWhiteLabel
      />
      <Route
        name='settings.affiliate'
        path='affiliate'
        component={SettingsAffiliate}
      />
      <Route
        name='settings.memberships'
        path='memberships'
        component={SettingsMemberships}
        allowWhiteLabel
      />
      <Route
        name='settings.patreon'
        path='patreon'
        component={SettingsPatreon}
      />
      <Route
        name='settings.cancel'
        path='subscriptions/cancel'
        component={ComponentForDefaultOrWhitelabelSite(SettingsCancel, SettingsCancelWhitelabel)}
        allowWhiteLabel
      />
      <Route
        name='settings.rss_feed'
        path='rss_feed'
        component={SettingsRssFeed}
        allowWhiteLabel
      />
      <Route
        name='settings.password'
        path='password'
        component={SettingsPassword}
        allowWhiteLabel
      />
      <Route
        name='settings.memberships.create'
        path='memberships/:membershipId'
        component={Checkout}
        allowWhiteLabel
      />
      <Route
        name='settings.privacy'
        path='privacy'
        component={SettingsPrivacy}
        allowWhiteLabel
      />
      <Route
        name="settings.page.show"
        path="pages/:pageId"
        component={PageShow}
        allowWhiteLabel
      />
    </Route>
    <Route
      name="privacy"
      path="/privacy"
      component={Privacy}
      signedOutRoute
    />
    <Route
      name="app"
      path="/app"
      component={AppListing}
      signedOutRoute
    />
    <Route
      name="partners"
      path="/partners"
      component={Partners}
      signedOutRoute
    />
    <Route
      name="legal"
      path="/legal"
      component={Legal}
      signedOutRoute
    />
    <Route
      name="terms"
      path="/terms"
      component={Terms}
      signedOutRoute
    />
    <Route
      name="block_notifications"
      path="/block_notifications"
      component={BlockNotifications}
      signedOutRoute
    />
    <Route
      name="faq"
      path="/faq"
      component={Faq}
      signedOutRoute
    />
    <Route
      name="help_center"
      path="/help_center"
      component={HelpCenter}
      signedOutRoute
    />
    <Route
      name="assign_membership_group"
      path="/assign_membership_group/:membershipGroupId"
      component={AssignMembershipGroup}
      signedOutRoute
    />
    <Route
      name="faq_categories.show"
      path="/help_center/topic/:faqCategoryId"
      component={FaqCategoryShow}
      signedOutRoute
    />
    <Route
      name="faq_items.show"
      path="/help_center/topic/:faqCategoryId/article/:faqItemId"
      component={FaqItemShow}
      signedOutRoute
    />
    <Route
      name="site_releases"
      path="/site_releases"
      component={SiteReleases}
      signedOutRoute
    />
    <Route
      name="forum"
      component={Forum}
    >
      <Route
        name="forums.index"
        path="/discussions"
        component={ForumIndex}
      />
      <Route
        name="forums.show"
        path="/discussions/:forumId/page/:page"
        component={ForumShow}
      />
      <Route
        name="forums.forum_threads.create"
        path="/discussions/:forumId/threads/create"
        component={ForumThreadCreate}
      />
      <Route
        name="forums.forum_threads.show"
        path="/discussions/:forumId/threads/:forumThreadId"
        component={ForumThreadShow}
      />
    </Route>
    <Route
      name="team"
      path="/team"
      component={Team}
      signedOutRoute
    />
    <Route
      name="contact"
      path="/contact"
      component={Contact}
      signedOutRoute
      allowWhiteLabel
    />
    <Route
      name="account_deletion"
      path="/account_deletion"
      component={AccountDeletion}
      signedOutRoute
      allowWhiteLabel
    />
    <Route
      name="profiles.show"
      path="/profile/:userId"
      component={ProfileHome}
    />
    <Route
      name='decks.show'
      path='decks/:deckId'
      component={DeckShow}
    />
    <Route
      name='tree_nodes.decks.show'
      path='/topics/:treeNodeId/decks/:deckId'
      component={DeckShow}
    />
    <Route
      name='chats.index'
      path='/chats'
      component={Chat}
    />
    <Route
      name='reviews.decks.show'
      path='/reviews/decks/:deckId'
      component={DeckShow}
    />
    <Route
      name='feeds.index'
      path='/feeds'
      component={Feed}
    />
    <Route
      name='my.feed'
      path='/feeds/mine'
      component={Feed}
    />
    <Route
      name='followers.feed'
      path='/feeds/following'
      component={Feed}
    />
    <Route
      name='feed_entry.show'
      path='/feeds/:feedEntryId'
      component={FeedEntryShow}
    />
    <Route
      name='more'
      path='/more'
      component={More}
    />
    <Route
      name='chats.index'
      path='/chats'
      component={Chat}
    >
      <Route
        name='chats.create_with_user'
        path='/chats/create/user/:userId'
        component={ChatEdit}
      />
      <Route
        name='chats.create'
        path='/chats/create'
        component={ChatEdit}
      />
      <Route
        name='chats.show'
        path='/chats/:chatId'
        component={ChatShow}
      />
      <Route
        name='chats.edit'
        path='/chats/:chatId/edit'
        component={ChatEdit}
      />
    </Route>
    <Route
      name="classroom"
      component={BackendClassroom}
    >
      <Route
        name='classroom.dashboard'
        path="classroom/:groupId/dashboard"
        component={ClassroomDashboard}
      />
      <Route
        name='classroom.settings'
        path="classroom/:groupId/settings"
        component={ClassroomSettings}
      />
      <Route
        name='classroom.students'
        path="classroom/:groupId/students"
        component={ClassroomStudents}
      />
    </Route>
    <Route
      name="creator"
      path="creator"
      component={Creator}
      allowWhiteLabel
    >
      <Route
        name='creator.groups.show'
        path="groups/:groupId"
        component={CreatorGroupShow}
        allowWhiteLabel
      >
        <Route
          name="creator.exercise_types.index"
          path="exercise_types"
          component={ExerciseTypeIndex}
          allowWhiteLabel
        />
        <Route
          name="creator.exercises.dashboard"
          path="dashboard"
          component={ExercisesDashboard}
          allowWhiteLabel
        />
        <Route
          name="creator.exercises.analytics"
          path="analytics"
          component={Analytics}
          allowWhiteLabel
        />
        <Route
          name="creator.user_exercises.index"
          path="user_exercises"
          component={UserExerciseIndex}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.patreon_users.index"
          path="patreon_users"
          component={PatreonUsersIndex}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.patreon_users.edit"
          path="patreon_users/:patreonUserId"
          component={PatreonUsersEdit}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.users.index"
          path="users"
          component={UsersIndex}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.admins.index"
          path="admins"
          component={AdminsIndex}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.users.edit"
          path="users/:userId"
          component={UsersEdit}
          allowWhiteLabel
        />
        <Route
          name="creator.campaign_messages.show"
          path="campaign_messages/:campaignMessageId"
          component={CampaignMessageShow}
        />
        <Route
          name="creator.groups.tags.index"
          path="/groups/:groupId/tags"
          component={TagsIndex}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.worksheets.index"
          path="posts"
          component={CreatorWorksheetIndex}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.exercises.index"
          path="exercises"
          component={CreatorExerciseIndex}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.worksheets.edit"
          path="posts/:worksheetId"
          component={WorksheetEdit}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.vocab.edit"
          path="posts/:worksheetId/vocab"
          component={VocabEdit}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.exercises.edit"
          path="posts/:worksheetId/exercises/:exerciseId"
          component={ExerciseEdit}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.site_layout"
          path="layout"
          component={SiteLayout}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.language_edit"
          path="language/:languageId"
          component={SiteLanguageEdit}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.site_navigation"
          path="navigation"
          component={SiteNavigation}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.site_pages"
          path="pages"
          component={SitePageIndex}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.custom_tabs"
          path="custom_tabs"
          component={CustomTabIndex}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.site_pages.edit"
          path="pages/:pageId/edit"
          component={SitePageEdit}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.membership_groups"
          path="membership_groups"
          component={SiteMembershipGroupIndex}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.membership_groups.edit"
          path="membership_groups/:membershipGroupId/edit"
          component={SiteMembershipGroupEdit}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.memberships.edit"
          path="memberships/:membershipId/edit"
          component={SiteMembershipEdit}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.membership_types.index"
          path="membership_types"
          component={SiteMembershipTypeIndex}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.membership_types.edit"
          path="membership_types/:membershipTypeId/edit"
          component={SiteMembershipTypeEdit}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.subscriptions.index"
          path="subscriptions"
          component={SiteSubscriptionIndex}
          allowWhiteLabel
        />
        <Route
          name="creator.groups.subscriptions.edit"
          path="subscriptions/:subscriptionId/edit"
          component={SiteSubscriptionEdit}
          allowWhiteLabel
        />
        <Route
          name="creator.campaign_types.index"
          path="campaign_types"
          component={SiteCampaignTypeIndex}
        />
        <Route
          name="creator.campaign_types.edit"
          path="campaign_types/:campaignTypeId"
          component={SiteCampaignTypeEdit}
        />
        <Route
          name="creator.campaigns.edit"
          path="campaign_types/:campaignTypeId/campaigns/:campaignId"
          component={SiteCampaignEdit}
        />
        <Route
          name="creator.emails.index"
          path="emails"
          component={EmailIndex}
        />
        <Route
          name="creator.grid_items.index"
          path="grid_items"
          component={GridItemIndex}
        />
        <Route
          name="creator.grid_items.edit"
          path="grid_items/:gridItemId"
          component={GridItemEdit}
        />
        <Route
          name="creator.podcast_episodes.index"
          path="podcast_episodes"
          component={PodcastEpisodeIndex}
        />
        <Route
          name="creator.podcast_episodes.edit"
          path="podcast_episodes/:podcastEpisodeId"
          component={PodcastEpisodeEdit}
        />
        <Route
          name="creator.podcasts.index"
          path="podcasts"
          component={PodcastIndex}
        />
        <Route
          name="creator.podcasts.edit"
          path="podcasts/:podcastId"
          component={PodcastEdit}
        />
        <Route
          name="creator.worksheet.comments"
          path="worksheet_comments"
          component={CreateWorksheetComments}
        />
        <Route
          name="creator.exercise.ratings"
          path="ratings"
          component={ExerciseRatingsIndex}
        />
        <Route
          component={ForumThreadShow}
          name="creator.forum_threads.show"
          path="/groups/:groupId/threads/:forumThreadId"
        />
        <Route
          name="creator.payment_migration.index"
          path="payment_migration"
          component={PaymentMigrationIndex}
        />
        <Route
          name="creator.download_link_types.index"
          path="download_link_types"
          component={DownloadLinkTypeIndex}
        />
        <Route
          name="creator.download_link_types.edit"
          path="download_link_types/:downloadLinkTypeId"
          component={DownloadLinkTypeEdit}
        />
        <Route
          name="creator.banner_notices.index"
          path="banner_notices"
          component={BannerNoticeIndex}
        />
        <Route
          name="creator.banner_notices.edit"
          path="banner_notices/:bannerNoticeId"
          component={BannerNoticeEdit}
        />
        <Route
          name="creator.groups.custom_tabs.edit"
          path="custom_tabs/:customTabId"
          component={CustomTabEdit}
        />
      </Route>
      <Route
        component={ProducerNavigation}
        allowWhiteLabel
      >
        <Route
          name="creator.producer.concepts.index"
          path="decks"
          component={ProducerConcept}
          allowWhiteLabel
        />
        <Route
          name="creator.producer"
          path="decks/:conceptId/producer"
          component={Producer}
          allowWhiteLabel
        />
        <Route
          name="creator.producer.video_clips.index"
          path="video_clips"
          component={VideoClips}
        />
        <Route
          name="creator.producer.recording_sessions.index"
          path="recording_sessions"
          component={RecordingSessionsIndex}
        />
        <Route
          name="creator.producer.recording_sessions.show"
          path="recording_sessions/:recordingSessionId"
          component={RecordingSessionsShow}
        />
        <Route
          name="creator.producer.sentences.index"
          path="sentences"
          component={Sentences}
        />
        <Route
          name="creator.producer.words.index"
          path="words/page/:page"
          component={CreatorWordIndex}
        />
        <Route
          name='creator.sentences.edit'
          path="sentences/:sentenceId"
          component={CreatorSentenceEdit}
        />
      </Route>
    </Route>
    <Route
      name="builder"
      path="builder"
      component={Builder}
    >
      <Route
        name="dashboard"
        path="dashboard"
        component={BuilderDashboard}
      >
        <Route
          name="builder.dashboard.decks"
          path="decks"
          component={DecksDashboard}
        />
        <Route
          name="builder.dashboard.analytics_with_month"
          path="analytics/year/:year/month/:month"
          component={Analytics}
        />
        <Route
          name="builder.dashboard.analytics"
          path="analytics"
          component={Analytics}
        />
        <Route
          name="builder.dashboard.payments"
          path="payments"
          component={PaymentsDashboard}
        />
        <Route
          name="builder.dashboard.campaign_messages"
          path="campaign_messages"
          component={CampaignMessagesDashboard}
        />
        <Route
          name="builder.dashboard.exercises"
          path="exercises"
          component={ExercisesDashboard}
        />
        <Route
          name="builder.dashboard.subscriptions"
          path="subscriptions"
          component={SubscriptionsDashboard}
        />
        <Route
          name="builder.dashboard.didactics"
          path="didactics"
          component={DidacticsDashboard}
          allowWhiteLabel
        />
        <Route
          name="builder.dashboard.users"
          path="users"
          component={UsersDashboard}
        />
        <Route
          name="builder.dashboard.levels"
          path="levels"
          component={LevelsDashboard}
        />
      </Route>
      <Route
        name="mailings"
        path=""
        component={Outreach}
      >
        <Route
          name="builder.referrer_options.index"
          path="referrer_options"
          component={ReferrerOptionIndex}
        />
        <Route
          name="builder.promotions.index"
          path="promotions"
          component={PromotionIndex}
        />
        <Route
          name="builder.promotions.edit"
          path="promotions/:promotionId"
          component={PromotionEdit}
        />
        <Route
          name="builder.notifications.index"
          path="notifications"
          component={BuilderNotificationIndex}
        />
        <Route
          name="builder.campaign_messages.index"
          path="campaign_messages"
          component={CampaignMessageIndex}
        />
        <Route
          name="builder.campaign_types.index"
          path="campaign_types"
          component={CampaignTypeIndex}
        />
        <Route
          name="builder.campaign_types.edit"
          path="campaign_types/:campaignTypeId"
          component={CampaignTypeEdit}
        />
        <Route
          name="builder.campaigns.edit"
          path="campaign_types/:campaignTypeId/campaigns/:campaignId"
          component={CampaignEdit}
        />
        <Route
          name="builder.notifications.send_notification"
          path="notifications/send_notification"
          component={SendNotification}
        />
        <Route
          name="builder.campaign_messages.show"
          path="campaign_messages/:campaignMessageId"
          component={CampaignMessageShow}
        />
      </Route>
      <Route
        name="concepts"
        path=""
        component={Concept}
      >
        <Route
          name="builder.concepts.index"
          path="concepts"
          component={ConceptIndex}
        />
        <Route
          name="builder.concepts.edit"
          path="concepts/:conceptId"
          component={ConceptEdit}
        />
        <Route
          name="builder.concept_categories.index"
          path="concepts_categories"
          component={ConceptCategoryIndex}
        />
        <Route
          name="builder.concept_categories.edit"
          path="concepts_categories/:conceptCategoryId"
          component={ConceptCategoryEdit}
        />
      </Route>
      <Route
        name=""
        path=""
        component={DeckNav}
      >
        <Route
          name="builder.trees.edit"
          path="trees/:treeId"
          component={TreeEdit}
        />
        <Route
          name="builder.tree_modules.edit"
          path="trees/:treeId/tree_modules/:treeModuleId"
          component={TreeModuleEdit}
        />
        <Route
          name="builder.trees_modules.index"
          path="trees/:treeId/tree_modules"
          component={TreeModuleIndex}
        />
        <Route
          name="builder.trees.index"
          path="trees"
          component={TreeIndex}
        />
        <Route
          name="builder.tree_nodes.edit"
          path="trees/:treeId/tree_modules/:treeModuleId/tree_nodes/:treeNodeId"
          component={TreeNodeEdit}
        />
        <Route
          name="builder.tree_nodes.direct_edit"
          path="tree_nodes/:treeNodeId"
          component={TreeNodeEdit}
        />
        <Route
          name="builder.tree_nodes_without_tree.edit"
          path="tree_nodes/:treeNodeId"
          component={TreeNodeEdit}
        />
        <Route
          name="builder.trees.concepts.edit"
          path="trees/:treeId/tree_modules/:treeModuleId/tree_nodes/:treeNodeId/concepts/:conceptId"
          component={ConceptStudio}
        />
        <Route
          name="builder.decks.index"
          path="decks/page/:page"
          component={DeckIndex}
        />
        <Route
          name="builder.interjections.index"
          path="interjections"
          component={InterjectionIndex}
        />
        <Route
          name="builder.interjections.edit"
          path="interjections/:interjectionId"
          component={InterjectionEdit}
        />
        <Route
          name="builder.user_decks.index"
          path="user_decks"
          component={UserDeckIndex}
        />
        <Route
          name="builder.decks.user_decks.index"
          path="decks/:deckId/user_decks"
          component={UserDeckIndex}
        />
        <Route
          name="builder.ratings.index"
          path="ratings"
          component={RatingIndex}
        />
        <Route
          name="builder.ratings.edit"
          path="ratings/:ratingId"
          component={RatingEdit}
        />
        <Route
          name="builder.rating_types.index"
          path="rating_types"
          component={RatingTypeIndex}
        />
        <Route
          name="builder.rating_types.edit"
          path="rating_types/:ratingTypeId"
          component={RatingTypeEdit}
        />
        <Route
          name="builder.decks.edit"
          path="decks/:deckId"
          component={DeckEdit}
        />
        <Route
          name="builder.trees.decks.edit"
          path="trees/:treeId/tree_modules/:treeModuleId/tree_nodes/:treeNodeId/decks/:deckId"
          component={DeckEdit}
        />
        <Route
          name="builder.deck_rulebooks.index"
          path="deck_rulebooks"
          component={DeckRulebookIndex}
        />
        <Route
          name="builder.deck_rulebooks.edit"
          path="deck_rulebooks/:deckRulebookId"
          component={DeckRulebookEdit}
        />
        <Route
          name="builder.card_types.index"
          path="card_types"
          component={CardTypeIndex}
        />
        <Route
          name="builder.card_types.edit"
          path="card_types/:cardTypeId"
          component={CardTypeEdit}
        />
      </Route>
      <Route
        name="subscriptions"
        path=""
        component={Subscription}
      >
        <Route
          name="builder.payment_intents.index"
          path="/payment_intents"
          component={PaymentIntentIndex}
        />
        <Route
          name="builder.affiliate_payouts.index"
          path="/affiliate_payouts"
          component={AffiliatePayoutIndex}
        />
        <Route
          name="builder.affiliate_payouts.show"
          path="/affiliate_payouts/:affiliatePayoutId"
          component={AffiliatePayoutShow}
        />
        <Route
          name="builder.subscriptions.index"
          path="subscriptions"
          component={SubscriptionIndex}
        />
        <Route
          name="builder.subscriptions.edit"
          path="subscriptions/:subscriptionId"
          component={SubscriptionEdit}
        />
        <Route
          name="builder.subscriptions.payments.edit"
          path="subscriptions/:subscriptionId/payments/:paymentId"
          component={PaymentEdit}
        />
        <Route
          name="builder.payments.edit"
          path="payments/:paymentId"
          component={PaymentEdit}
        />
        <Route
          name="builder.payments.index"
          path="payments"
          component={PaymentIndex}
        />
        <Route
          name="builder.memberships.index"
          path="memberships"
          component={BuilderMembershipIndex}
        />
        <Route
          name="builder.memberships.edit"
          path="memberships/:membershipId"
          component={MembershipEdit}
        />
        <Route
          name="builder.membership_types.index"
          path="membership_types"
          component={MembershipTypeIndex}
        />
        <Route
          name="builder.membership_types.edit"
          path="membership_types/:membershipTypeId"
          component={MembershipTypeEdit}
        />
        <Route
          name="builder.membership_groups.index"
          path="membership_groups"
          component={MembershipGroupIndex}
        />
        <Route
          name="builder.membership_groups.edit"
          path="membership_groups/:membershipGroupId"
          component={MembershipGroupEdit}
        />
      </Route>
      <Route
        name="site"
        path=""
        component={Site}
      >
        <Route
          name="builder.banner_notices.index"
          path="banner_notices"
          component={BannerNoticeIndex}
        />
        <Route
          name="builder.banner_notices.edit"
          path="banner_notices/:bannerNoticeId"
          component={BannerNoticeEdit}
        />
        <Route
          name="builder.groups.index"
          path="groups"
          component={GroupIndex}
        />
        <Route
          name="builder.groups.edit"
          path="groups/:groupId"
          component={GroupEdit}
        />
        <Route
          name="builder.site.index"
          path="sites"
          component={SiteIndex}
        />
        <Route
          name="builder.site.edit"
          path="sites/:siteId"
          component={SiteEdit}
        />
        <Route
          name="builder.page.index"
          path="pages"
          component={PageIndex}
        />
        <Route
          name="builder.page.show"
          path="pages/:pageId"
          component={PageShow}
        />
        <Route
          name="builder.page.edit"
          path="pages/:pageId/edit"
          component={PageEdit}
        />
        <Route
          name="builder.job.index"
          path="jobs"
          component={JobIndex}
        />
        <Route
          name="builder.job.edit"
          path="jobs/:jobId/edit"
          component={JobEdit}
        />
        <Route
          name="builder.faq_categories.index"
          path="faq_categories"
          component={FaqCategoryIndex}
        />
        <Route
          name="builder.faq_categories.edit"
          path="faq_categories/:faqCategoryId"
          component={FaqCategoryEdit}
        />
        <Route
          name="builder.faq_items.edit"
          path="faq_categories/:faqCategoryId/faq_items/:faqItemId"
          component={FaqItemEdit}
        />
        <Route
          name="builder.site_releases.edit"
          path="site_release/:siteReleaseId"
          component={SiteReleaseEdit}
        />
        <Route
          name="builder.site_releases.index"
          path="site_releases"
          component={SiteReleaseIndex}
        />
        <Route
          name="builder.error_logs.index"
          path="error_logs"
          component={ErrorLogIndex}
        />
        <Route
          name="builder.meetups.edit"
          path="meetups"
          component={MeetupsEdit}
        />
        <Route
          name="builder.forums.index"
          path="forums"
          component={BuilderForumIndex}
        />
        <Route
          name="builder.forums.edit"
          path="forums/:forumId"
          component={ForumEdit}
        />
      </Route>
      <Route
        name="users"
        path=""
        component={User}
      >
        <Route
          name="builder.users.index"
          path="users"
          component={UserIndex}
        />
        <Route
          name="builder.users.edit"
          path="users/:userId"
          component={UserEdit}
        />
        <Route
          name="builder.invitations.index"
          path="invitations"
          component={InvitationIndex}
        />
        <Route
          name="builder.challenges.index"
          path="challenges"
          component={ChallengeIndex}
        />
        <Route
          name="builder.sessions.index"
          path="users/:userId/sessions"
          component={SessionIndex}
        />
        <Route
          name="builder.site_notices.index"
          path="site_notices"
          component={SiteNoticeIndex}
        />
        <Route
          name="builder.blog_entries.index"
          path="blog_entries"
          component={BlogEntryIndex}
        />
        <Route
          name="builder.blog_entries.edit"
          path="blog_entries/:blogEntryId"
          component={BlogEntryEdit}
        />
        <Route
          name="builder.contact_messages.index"
          path="contact_messages"
          component={ContactMessageIndex}
        />
        <Route
          name="builder.contact_messages.show"
          path="contact_messages/:contactMessageId"
          component={ContactMessageShow}
        />
        <Route
          name="builder.site_notices.edit"
          path="site_notices/:siteNoticeId"
          component={SiteNoticeEdit}
        />
        <Route
          name="builder.chats.index"
          path="chats"
          component={BuilderChatIndex}
        />
        <Route
          name="builder.chats.show"
          path="chats/:chatId"
          component={BuilderChatShow}
        />
        <Route
          name="builder.languages.index"
          path="languages"
          component={BuilderLanguageIndex}
        />
        <Route
          name="builder.languages.edit"
          path="languages/:languageId"
          component={LanguageEdit}
        />
      </Route>
      <Route
        name="translations"
        path=""
        component={Translation}
      >
        <Route
          name="builder.video_clips.index"
          path="video_clips"
          component={VideoClipIndex}
        />
        <Route
          name="builder.video_clips.edit"
          path="video_clips/:videoClipId"
          component={VideoClipEdit}
        />
        <Route
          name="builder.words.index"
          path="words/page/:page"
          component={WordIndex}
        />
        <Route
          name="builder.sentences.index"
          path="sentences"
          component={SentenceIndex}
        />
        <Route
          name="builder.sentences.edit"
          path="sentences/:sentenceId"
          component={SentenceEdit}
        />
        <Route
          name="builder.words.edit"
          path="words/:wordId"
          component={WordEdit}
        />
        <Route
          name="builder.sentence_submissions.index"
          path="sentence_submissions"
          component={SentenceSubmissionIndex}
        />
        <Route
          name="builder.card_submissions.index"
          path="card_submissions/page/:page"
          component={UserSubmissionIndex}
        />
        <Route
          name="builder.sources.index"
          path="sources"
          component={SourceIndex}
        />
        <Route
          name="builder.translation_sources.index"
          path="translation_sources"
          component={TranslationSourceIndex}
        />
        <Route
          name="builder.translation_sources.edit"
          path="translation_sources/:translationSourceId"
          component={TranslationSourceEdit}
        />
        <Route
          name="builder.word_types.index"
          path="word_types"
          component={WordTypeIndex}
        />
        <Route
          name="builder.word_types.edit"
          path="word_types/:wordTypeId"
          component={WordTypeEdit}
        />
        <Route
          name="builder.sources.edit"
          path="sources/:sourceId"
          component={SourceEdit}
        />
        <Route
          name="builder.targets.index"
          path="targets"
          component={TargetIndex}
        />
        <Route
          name="builder.targets.edit"
          path="targets/:targetId"
          component={TargetEdit}
        />
        <Route
          name="builder.levels.index"
          path="levels"
          component={LevelIndex}
        />
        <Route
          name="builder.levels.edit"
          path="levels/:levelId"
          component={LevelEdit}
        />
      </Route>
    </Route>
    <Route
      name="discord"
      path="/discord"
      component={Discord}
      signedOutRoute
    />
    <Route
      name="page.show"
      path="pages/:pageId"
      component={PageShow}
      allowWhiteLabel
    />
    <Route
      name="page.show.slug"
      path="pages/slug/:slug"
      component={PageShowBySlug}
      allowWhiteLabel
    />
    <Redirect from="groups/:groupId/worksheets/:worksheetId" to="posts/:worksheetId" />
    <Redirect from="groups/:groupId/worksheets/:worksheetId/vocab" to="posts/:worksheetId/vocab" />
    <Redirect from="groups/:groupId/worksheets/:worksheetId/comments" to="posts/:worksheetId/comments" />
    <Redirect from="worksheets/:worksheetId" to="posts/:worksheetId" />
    <Redirect from="worksheets/:worksheetId/vocab" to="posts/:worksheetId/vocab" />
    <Redirect from="worksheets/:worksheetId/comments" to="posts/:worksheetId/comments" />
    <Redirect from="worksheets" to="posts" />
    <Redirect from='*' to="/redirect" />
  </Route>
);

export default routes;
