import React from 'react';
import { observer } from 'mobx-react';
import { AppUI } from '@seedlang/state';
import { Link } from 'react-router';
import styled from '@emotion/styled';
import { flexCenter } from '@seedlang/style_mixins';
import { computed } from "mobx";

const gridTemplateFor = ({ isMobile, menuPosition }) => {
  if (isMobile) {
    return { columns: 'auto', areas: '"menu" "content"' } // each quoted section represents one row
  }
  if (menuPosition === 'right') {
    return { columns: '1fr auto', areas: '"content menu"' }
  }
  return { columns: 'auto 1fr', areas: '"menu content"' }
}

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  display: grid;
  grid-template-columns: ${(props) => gridTemplateFor(props).columns};
  grid-template-areas: ${(props) => gridTemplateFor(props).areas};
  grid-gap: 15px;
  align-items: start;
`;

const ContentContainer = styled.div`
  grid-area: content;
  margin: ${(props) => props.margin};
  padding: 20px;
  border-radius: ${props => props.borderRadius};
  overflow: hidden;
  background: white;
`;

const MenuContainer = styled.div`
  grid-area: menu;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  border-radius: ${props => props.borderRadius};
  overflow: hidden;
`;

const Content = styled.div`
  background: white;
  padding: 10px;
  h3 {
    margin: 0 0 20px 0;
  }
`;

const Row = styled.div`
  ${flexCenter()}
  background: ${(props) => props.selected ? "#DDD" : "#FFF"};
  font-weight: bold;
  cursor: pointer;
  height: ${(props) => props.height};
  display: flex;
  position: relative;
  margin: 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
  padding: 0 10px;
`;

@observer
class Settings extends React.Component {

  @computed get navigationItems() {
    return AppUI.site.navigationItems.filter(item => item.settingsLink && item.path.startsWith('/pages/')).map(item => ({name: item.name, id: item.id, pageId: item.path.match(/(?<=\/pages\/).*/)[0]}));
  }

  render() {
    return (
      <Wrapper
        isMobile={AppUI.layout.isMobile}
        menuPosition={AppUI.siteIsDefault ? 'right' : 'left'}
        margin={AppUI.layout.isMobile ? '10px 0 0 0' : null}
      >
        <ContentContainer
          borderRadius={AppUI.layout.isMobile ? 0 : '10px'}
        >
          <Content>
            {this.props.children}
          </Content>
        </ContentContainer>
        {
          AppUI.userIsSignedIn &&
            <MenuContainer
              width={AppUI.layout.isMobile ? "100%" : "250px"}
              borderRadius={AppUI.layout.isMobile ? 0 : '10px'}
            >
              <Link
                to={{name: 'settings.user_profile'}}
              >
                <Row
                  height={AppUI.layout.isMobile ? "35px" : "68px"}
                  selected={this.props.children && this.props.children.props.route.name === 'settings.user_profile'}
                >
                  User Settings
                </Row>
              </Link>
              {
                AppUI.siteIsDefault &&
                  <Link
                    to={{name: 'settings.deck'}}
                  >
                    <Row
                      height={AppUI.layout.isMobile ? "35px" : "68px"}
                      selected={this.props.children && this.props.children.props.route.name === 'settings.deck'}
                    >
                      Deck Settings
                    </Row>
                  </Link>
              }
              {
                !AppUI.user.guest && 
                  <Link
                    to={{name: 'settings.password'}}
                  >
                    <Row
                      height={AppUI.layout.isMobile ? "35px" : "68px"}
                      selected={this.props.children && this.props.children.props.route.name === 'settings.password'}
                    >
                      Change Password
                    </Row>
                  </Link>
              }
              {
                AppUI.siteIsDefault &&
                  <Link
                    to={{name: 'settings.clear_progress'}}
                  >
                    <Row
                      height={AppUI.layout.isMobile ? "35px" : "68px"}
                      selected={this.props.children && this.props.children.props.route.name === 'settings.clear_progress'}
                    >
                      Clear Progress
                    </Row>
                  </Link>
              }
              {
                AppUI.siteIsDefault &&
                  <Link
                    to={{name: 'settings.streak_repair'}}
                  >
                    <Row
                      height={AppUI.layout.isMobile ? "35px" : "68px"}
                      selected={this.props.children && this.props.children.props.route.name === 'settings.streak_repair'}
                    >
                      Streak Repair
                    </Row>
                  </Link>
              }
              <Link
                to={{name: 'settings.user_notifications'}}
              >
                <Row
                  height={AppUI.layout.isMobile ? "35px" : "68px"}
                  selected={this.props.children && this.props.children.props.route.name === 'settings.user_notifications'}
                >
                  Notifications
                </Row>
              </Link>
              {
                (AppUI.siteIsDefault || (AppUI.site.allowPatreonSignIn && AppUI.site.patreonClientId)) &&
                  <Link
                    to={{name: 'settings.patreon'}}
                  >
                    <Row
                      height={AppUI.layout.isMobile ? "35px" : "68px"}
                      selected={this.props.children && this.props.children.props.route.name === 'settings.patreon'}
                    >
                      Patreon Account
                    </Row>
                  </Link>
              }
              <Link
                to={{name: 'settings.billing'}}
              >
                <Row
                  height={AppUI.layout.isMobile ? "35px" : "68px"}
                  selected={this.props.children && this.props.children.props.route.name === 'settings.billing'}
                >
                  {AppUI.siteIsDefault ? 'Manage Subscription' : 'Manage Membership'}
                </Row>
              </Link>
              {
                (AppUI.user.admin || AppUI.user.userPodcasts.length > 0) &&
                  <Link
                    to={{name: 'settings.rss_feed'}}
                  >
                    <Row
                      height={AppUI.layout.isMobile ? "35px" : "68px"}
                      selected={this.props.children && this.props.children.props.route.name === 'settings.rss_feed'}
                    >
                      Audio Feeds
                    </Row>
                  </Link>
              }
              <Link
                to={{ name: 'settings.privacy' }}
              >
                <Row
                  height={AppUI.layout.isMobile ? "35px" : "68px"}
                  selected={this.props.children && this.props.children.props.route.name === 'settings.privacy'}
                >
                  Privacy
                </Row>
              </Link>
              {
                this.navigationItems.map(navigationItem => {
                  return (
                    <Link
                      key={navigationItem.id}
                      to={{ name: 'settings.page.show', params: {pageId: navigationItem.pageId} }}
                    >
                      <Row
                        height={AppUI.layout.isMobile ? "35px" : "68px"}
                        selected={this.props.children && this.props.children.props.route.name === 'settings.page.show' && this.props.params['pageId'] === navigationItem.pageId}
                      >
                        {navigationItem.name}
                      </Row>
                    </Link>
                  );
                })
              }
            </MenuContainer>
        }
      </Wrapper>
    );
  }
}

export default Settings;
